@font-face {
  font-family: "Simplon Norm";
  src: url("assets/fonts/SimplonNorm-Regular.otf") format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

.tabSelected::selection {
  color: "orange";
  border-bottom: "2px solid orange";
}

/* Example Styles for React Tags*/

.container {
  margin: auto;
  width: 50%;
}
.ReactTags__tags {
  position: relative;
}

.ReactTags__clearAll {
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  background: #3ab689;
  color: #fff;
  border: none;
}

/* Styles for the input */
.ReactTags__tagInput {
  /* border-radius: 2px; */
  display: inline-block;
}
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  border: 1px solid rgb(255, 255, 255);
  min-width: 150px;
}

.ReactTags__editInput {
  border-radius: 1px;
}

.ReactTags__editTagInput {
  display: inline-flex;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
  /* border: 1px solid #ddd; */
  background: #3ab689;
  color: white;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  border-radius: 20px;
  padding-left: "10px!important";
  padding-right: "10px!important";
}
.ReactTags__selected a.ReactTags__remove {
  color: #fff;
  margin-left: 10px;
  cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
  position: absolute;
}
.ReactTags__suggestions ul {
  list-style-type: none;
  /* box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2); */
  background: white;
  width: 200px;
}
.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  margin-left: "10px";
  color: white;
}

*:focus {
  box-shadow: none !important;
}
.splide__arrow--prev {
  display: none;
  left: -2.5em !important;
}
.splide__arrow--next {
  display: none;
  right: -0.5em !important;
}
*:focus {
  box-shadow: none !important;
}

.twolines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
/* 
MENU CSS */
.menuitem-container {
  font-family: sans-serif;
  padding: 3rem;
}
.slider-favorite {
  width: 200px;
  height: 195px;
  border-radius: 10px;
  overflow: hidden;

  margin-top: 1rem;
  border: 1px solid lightgray;
  box-shadow: var(--chakra-shadows-sm);
  cursor: pointer;
}
.item-box {
  display: flex;
  flex-wrap: wrap;
  gap: 0rem;
}
.food-box {
  display: flex;
  align-items: center;
  gap: 7px;
  flex-wrap: wrap;
}
.food-container {
  display: flex;
  cursor: pointer;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin-top: 1rem;
  width: 140px;
  height: 135px;
  background-color: #bfebbb;
  border-radius: 6px;
}
.food-img {
  width: 130px;
  height: 100px;
  border-radius: 8px;
}
.menu-image-container {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1rem;
}
.fooditem-container {
  margin-top: 1rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.5rem;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-bottom: 1px solid #dedede;
  padding-bottom: 0.5rem;
}
.resturant-box {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;
  width: 100px;
  height: 100px;
  padding: 22px;
  background-color: #f8f8f8;
  border-radius: 8px;
}
.responsive-text {
  font-size: 14px;
}
.menu-header {
  font-size: 14px;
}
.menu-header-img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}
.responsive-subtitle {
  margin-top: 5px;
}
.food-title {
  font-size: 16px;
  font-weight: 600;
}
.responsive-subtitle {
  font-weight: 600;
  font-size: 15px;
}
.menubill-container {
  min-width: 25rem;
  border-left: 1px solid lightgray;
  height: 90vh;
}
.menucategory-container {
  width: 145px;
  border-radius: 10px;
  border: 1px solid lightgray;
  overflow: hidden;
  margin-left: 1rem;
  margin-top: 1rem;
  box-shadow: var(--chakra-shadows-sm);
  cursor: pointer;
}
.menucategory-wrapper {
  margin-left: 2rem;
}
.button-bottom {
  display: none !important;
}
.payment_wrapper1 {
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.button_wrapper {
  display: flex;
  gap: 1rem;
  align-items: baseline;
}
/* 
MEDIA QUERY */
@media only screen and (max-width: 400px) {
  .responsive-text {
    font-size: 9px !important;
  }
  .responsive-subtitle {
    font-size: 10px !important;
  }
  .splide-container {
    margin-right: 0.5rem;
    margin-left: 0.25rem !important;
  }
  .splide__arrow--prev {
    left: -2.5em !important;
    display: none;
  }
  .splide__arrow--next {
    display: none;
    right: -0.5em !important;
  }
  .food-title {
    font-size: 12px !important;
  }
  .resturant-box {
    width: 72px !important;
    height: 72px !important;
    padding: 22px;
    background-color: #f8f8f8;
    border-radius: 8px;
  }

  .item-box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-left: -1rem;
    gap: 2px;
    margin-top: -1rem;
  }
  .resturant-box-text {
    margin-left: 0.5rem !important;
    margin-top: 0.5rem !important;
    font-size: 11px;
  }
  .food-img {
    width: 60px !important;
    height: 60px !important;
  }
  .fooditem-container {
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 6px !important;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-bottom: 1px solid #dedede;
    padding-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  .slider-favorite {
    width: 108px !important;
    height: 123px;
  }
}

@media only screen and (min-width: 400px) and (max-width: 700px) {
  .slider-favorite-img {
    height: 75px !important;
  }
  .food-title {
    font-size: 14px !important;
  }
  .slider-favorite {
    width: 120px !important;
    height: 120px;
  }
  .resturant-box {
    width: 75px !important;
    height: 75px !important;
    padding: 22px;
    background-color: #f8f8f8;
    border-radius: 8px;
  }
  .responsive-text {
    font-size: 10px !important;
  }
  .responsive-subtitle {
    font-size: 10px !important;
  }
  .splide-container {
    margin-left: 0rem !important;
  }
  .item-box {
    display: flex;

    margin-left: -1rem;
    gap: 2px;
    margin-top: -1rem;
  }
  .resturant-box {
    display: flex;
    align-items: center;
    width: 80px;
    height: 80px;
    padding: 22px;
    background-color: #f8f8f8;
    border-radius: 8px;
  }
}

@media only screen and (max-width: 700px) {
  .responsive-text {
    font-size: 9px !important;
  }
  .responsive-subtitle {
    font-size: 10px !important;
  }
  .food-title {
    font-size: 14px !important;
  }
  .slider-favorite-text {
    font-size: 11px !important;
  }
  .slider-favorite-img {
    height: 75px !important;
  }

  .menu-header {
    font-size: 12px !important;
  }
  .break-box {
    padding: 0rem 1rem !important;
  }
  .box-name {
    font-size: 16px;
  }
  .box-description {
    margin-top: 0rem !important;
    font-size: 13px;
  }
  .food-img {
    width: 90px;
    height: 90px;
  }

  /* .food-box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0px;
  } */
  .food-container {
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    margin-top: 16px;
    width: 80px;
    height: 80px;
    background-color: #bfebbb;
    border-radius: 6px;
  }
  .food-img {
    width: 65px !important;
    height: 60px !important;
  }
  .box-name {
    font-size: 12px !important;
  }
  .box-description {
    font-size: 9px !important;
  }
  .box-price {
    font-size: 9px !important;
  }
  .resturant-box {
    display: flex;
    align-items: center;
    width: 80px;
    height: 80px;
    padding: 22px;
    background-color: #f8f8f8;
    border-radius: 8px;
  }
}
@media only screen and (max-width: 700px) {
  .box-name {
    font-size: 16px;
  }
  .box-description {
    font-size: 13px;
  }
  .menu-image-container {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
  }
  .slider-favorite {
    width: 180px;
  }
  .resturant-box {
    display: flex;
    align-items: center;
    width: 70px !important;
    height: 70px !important;
    padding: 22px;
    background-color: #f8f8f8;
    border-radius: 8px;
  }
}
@media only screen and (min-width: 700px) and (max-width: 800px) {
  .resturant-box {
    display: flex;
    align-items: center;
    width: 80px;
    height: 80px;
    padding: 22px;
    background-color: #f8f8f8;
    border-radius: 8px;
  }
  .menu-header {
    font-size: 13px !important;
  }
  .food-title {
    font-size: 16px !important;
  }
  .responsive-text {
    font-size: 10px !important;
  }
  .splide-container {
    margin-left: 0rem !important;
  }
  .box-name {
    font-size: 12px !important;
  }
  .responsive-subtitle {
    font-size: 10px !important;
  }
  .slider-favorite-img {
    height: 75px !important;
  }
  .box-description {
    font-size: 11px !important;
  }
  .box-price {
    font-size: 11px !important;
  }
  .slider-favorite {
    width: 155px !important;
    height: 125px;
  }
  .food-box {
    display: flex;
    align-items: center;
    gap: 3px;
    flex-wrap: wrap;
  }
  .food-img {
    width: 80px;
    height: 75px;
  }
  .box-description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .slider-favorite {
    width: 180px;
  }
}
@media only screen and (min-width: 800px) and (max-width: 1000px) {
  .resturant-box {
    display: flex;
    align-items: center;
    width: 80px;
    height: 80px;
    padding: 22px;
    background-color: #f8f8f8;
    border-radius: 8px;
  }
  .menu-header {
    font-size: 13px !important;
  }
  .food-title {
    font-size: 16px !important;
  }
  .responsive-text {
    font-size: 10px !important;
  }
  .splide-container {
    margin-left: 0rem !important;
  }
  .box-name {
    font-size: 12px !important;
  }
  .responsive-subtitle {
    font-size: 10px !important;
  }
  .slider-favorite-img {
    height: 75px !important;
  }
  .box-description {
    font-size: 11px !important;
  }
  .box-price {
    font-size: 11px !important;
  }
  .slider-favorite {
    width: 155px !important;
    height: 125px;
  }
  .food-box {
    display: flex;
    align-items: center;
    gap: 3px;
    flex-wrap: wrap;
  }
  .food-img {
    width: 80px;
    height: 75px;
  }
  .box-description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .slider-favorite {
    width: 180px;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1400px) {
  .resturant-box {
    display: flex;
    align-items: center;
    width: 80px;
    height: 80px;
    padding: 22px;
    background-color: #f8f8f8;
    border-radius: 8px;
  }
  .menu-header {
    font-size: 13px !important;
  }
  .food-title {
    font-size: 16px !important;
  }
  .responsive-text {
    font-size: 10px !important;
  }
  .splide-container {
    margin-left: 0rem !important;
  }
  .box-name {
    font-size: 12px !important;
  }
  .responsive-subtitle {
    font-size: 10px !important;
  }
  .slider-favorite-img {
    height: 75px !important;
  }
  .box-description {
    font-size: 11px !important;
  }
  .box-price {
    font-size: 11px !important;
  }
  .slider-favorite {
    width: 155px !important;
    height: 125px;
  }
  .food-box {
    display: flex;
    align-items: center;
    gap: 3px;
    flex-wrap: wrap;
  }
  .food-img {
    width: 80px;
    height: 75px;
  }
  .box-description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .slider-favorite {
    width: 180px;
  }
}
@media only screen and (max-width: 1400px) {
  .responsive-title {
    margin-top: 16px !important;
  }
  .report-table {
    display: block !important;
  }
}
/* TAB VIEW */
@media (min-width: 768px) and (max-width: 1024px) {
  .menubill-container {
    display: none;
  }
  .menucategory-container {
    width: 120px;
    border-radius: 10px;
    border: 1px solid lightgray;
    overflow: hidden;
    margin-left: 1rem;
    margin-top: 1rem;
    box-shadow: var(--chakra-shadows-sm);
    cursor: pointer;
  }
  .menucategory-wrapper {
    margin-left: 0rem;
  }
  .button-bottom {
    display: block !important;
    position: fixed !important;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    border-radius: 25px !important;
    background: #38a169 !important;
    color: #fff;
  }
  .button-bottom1 {
    display: block !important;
    position: fixed !important;
    bottom: 3rem;
    left: 42%;
    transform: translateX(-50%);
    z-index: 999;
    background: #38a169 !important;
    color: #fff;
  }
  .button-bottom2 {
    display: block !important;
    position: fixed !important;
    bottom: 3rem;
    left: 60%;
    transform: translateX(-50%);
    z-index: 999;
    color: #fff;
  }
  /* PAYMENT PART */
}
